<script setup>
import {inject} from "vue";

let email = inject('email');
</script>

<template>
  <footer>
    <div class="ox-footer-section">
      <p><b>Contact:</b> <a :href="'mailto:'+email" class="ox-link">{{ email }}</a></p>
      <p><b>Copyright:</b> The Ox: Catholic Student Magazine Club at Iowa State University</p>
    </div>
  </footer>
</template>

<style scoped>
footer {
  width: 100%;
  background-color: var(--color-footer-background);
  color: var(--color-footer-text);
  font-family: serif;
  min-height: 10rem;
  padding: 2rem;
  display: flex;
  flex-flow: row-reverse;
}
.ox-footer-section {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  text-align: end;
}
</style>