<script>
export default {
  name: 'OxEvent',
  props: {
    date: String,
    allDay: Boolean,
    name: String,
    location: String,
    start: String,
    end: String,
    link: String,
    subEvents: Array,
  },
}
</script>

<template>
  <div style="color: var(--cream-dark); padding-left: 0.5rem" v-if='date && !allDay'> {{ date }} </div>
  <div style="color: var(--brown); padding-left: 0.5rem" v-if='date && allDay'> {{ date }} </div>
  <a :href="link" target="_blank" title="Add to Google Calendar">
    <div class="cool-event d-flex flex-column align-items-end" :class="allDay ? 'all-day-event' : 'normal-event'" style="padding: 0.6rem">
      <div class="d-flex flex-row align-items-center justify-content-between w-100">
        <div style="font-size: 18px; font-family: serif"><b>{{ name }}</b></div>
        <div>{{ start }}</div>
      </div>
      <div class="d-flex flex-column align-items-end gap-2 subEvents" v-if="subEvents">
        <div v-for="(event,index) in subEvents" :key="index" class="w-100">
          <OxEvent :name="event.name" :start="event.start" :end="event.end" :location="event.location" :link="event.link" :sub-events="event.subEvents" :all-day="event.allDay" :date="event.date"/>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between flex-row-reverse w-100">
        <div>{{ end }}</div>
        <div style="font-size: 12px" v-if="location">{{ location }}</div>
      </div>
    </div>
  </a>
</template>

<style scoped>
a {
  text-decoration: none;
}
.cool-event {
  font-family: serif;
  border-radius: 10px;
  padding: 0.5rem;
  width: 100%;
}

.all-day-event {
  color: var(--cream);
  background-color: var(--maroon);
  transition: all 0.5s ease;
  width: calc(100% - 1.1rem);
}
.all-day-event:hover {
  color: var(--cream-light);
  transition: all 0.5s ease;
}

.normal-event {
  color: var(--maroon);
  border-color: var(--maroon);
  border-style: solid;
  border-width: 2px;
  background-color: var(--cream);
  transition: all 0.5s ease;
}
.normal-event:hover {
  color: var(--maroon-bright);
  background-color: var(--cream-light);
}

.subEvents {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: -1.1rem;
}

@media (min-width: 992px) {
  .all-day-event {
    width: calc(100% - 2rem);
  }
  .subEvents {
    margin-right: -2rem;
  }
}
</style>