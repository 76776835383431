<script>
import {inject} from "vue";

export default {
  methods: {
    on() { document.getElementById("moneyOverlay").style.display = "block"; },
    off() { document.getElementById("moneyOverlay").style.display = "none"; }
  },
  data() {
    return {
      email: inject('email')
    }
  },
}
</script>

<template>
  <div id="moneyOverlay" class="overlay">
    <div class="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
      <div class="overlay-content">
        <div class="d-flex flex-row justify-content-between align-items-start">
          <h3>got money?</h3>
          <font-awesome-icon class="close-btn" @click="off" :icon="['fas', 'times']" />
        </div>
        <p>We'll keep this quick. We provide everything we do (events, publishing a magazine, the website, and more) absolutely free, and we love doing that. However, none of this is actually free. If you are able, your financial support would be greatly appreciated! The next generation of Catholic Creatives thanks you :) </p>
        <div class="d-flex flex-lg-row flex-column w-100 justify-content-center align-items-center gap-1">
<!--          <a href="" class="btn btn-primary btn-lg"><font-awesome-icon :icon="['fas', 'heart']" /> <b>Keep us free!</b> <font-awesome-icon :icon="['fas', 'heart']" /></a>-->
          <p style="font-size: 0.7rem; font-weight: lighter; font-style: italic;">Online giving still in progress - please reach out to us at <a :href="'mailto:'+email" class="ox-link">{{ email }}</a> to support <font-awesome-icon :icon="['fas', 'heart']" /></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 55;
}
.overlay-content {
  background-color: var(--cream);
  color: black;
  padding: 1rem;
  border-radius: 10px;
  width: 30%;
}
@media (max-width: 992px) {
  .overlay-content {
    width: 95%;
  }
}
h3 {
  color: var(--maroon);
  font-weight: bold;
}
.close-btn {
  color: var(--brown-light);
  transition: color 0.5s ease;
}
.close-btn:hover{
  color: var(--brown);
  cursor: pointer;
}
</style>