<script setup>

import OxEvent from "@/components/OxEvent.vue";

let events = [
  {
    id: 1,
    name: "Submissions Open!",
    allDay: true,
    date: "",
    location: "",
    start: "February 9th, 2024",
    end: "April 5th, 2024",
    link: "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MXBoczFzZDVjc2JicXJuNjRxbTM0dDI3ZmQgOThmMTRlYTJiZGVhOTI0MDQ3MTdlMTYzODhmNDYzMmMyYmJiN2VlN2RmYTE5OWNkZWQzZDhhMDRhMGQ5MmIxZEBn&tmsrc=98f14ea2bdea92404717e16388f4632c2bbb7ee7dfa199cded3d8a04a0d92b1d%40group.calendar.google.com",
    subEvents: [
      {
        id: 2,
        name: "Writing Night",
        allDay: false,
        location: "STA",
        date: "Friday, March 1st",
        start: "7:00 pm",
        end: "9:00 pm",
        link: "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=Mm4yYWNjcWNzYnFpYnZzZjNiOGsxNnVrc20gOThmMTRlYTJiZGVhOTI0MDQ3MTdlMTYzODhmNDYzMmMyYmJiN2VlN2RmYTE5OWNkZWQzZDhhMDRhMGQ5MmIxZEBn&tmsrc=98f14ea2bdea92404717e16388f4632c2bbb7ee7dfa199cded3d8a04a0d92b1d%40group.calendar.google.com",
      },
      {
        id: 3,
        name: "Art Night (Happy Birthday Elizabeth!)",
        allDay: false,
        location: "STA",
        date: "Friday, March 22nd",
        start: "7:00 pm",
        end: "9:00 pm",
        link: "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=N2xjcXFjcGUyY2hhOWI4YWN0ZmhtdWl2ZG4gOThmMTRlYTJiZGVhOTI0MDQ3MTdlMTYzODhmNDYzMmMyYmJiN2VlN2RmYTE5OWNkZWQzZDhhMDRhMGQ5MmIxZEBn&tmsrc=98f14ea2bdea92404717e16388f4632c2bbb7ee7dfa199cded3d8a04a0d92b1d%40group.calendar.google.com",
      }
    ]
  },
  {
    id: 4,
    name: "End of Semester Party!",
    allDay: true,
    location: "TBD",
    date: "TBD",
    start: "TBD",
    end: "TBD",
    link: "",
  }
]
</script>

<template>
  <div class="cool-calendar d-flex flex-column">
    <div class="d-flex flex-row justify-content-between">
      <h4 style="color: var(--color-calendar-text)"> Ox Calendar </h4>
      <a class="d-flex flex-row align-items-center" style="background-color: white; text-decoration: none; height: 20px" target="_blank" title="Add to Google Calendar" href="https://calendar.google.com/calendar/u/0?cid=OThmMTRlYTJiZGVhOTI0MDQ3MTdlMTYzODhmNDYzMmMyYmJiN2VlN2RmYTE5OWNkZWQzZDhhMDRhMGQ5MmIxZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t">
        <img alt="plus" height="20" width="20" src="@/assets/google-plus.png"/>
        <img alt="Google" style="padding-left: 3px; padding-right: 1px" width="51.75" height="18" src="@/assets/google-logo.png"/>
        <span style="color: #767676; font-family: 'Roboto Light',serif; padding-right: 3px; text-decoration: none">Calendar</span>
      </a>
    </div>
    <div class="d-flex flex-column" style="gap: 1rem; background-color: var(--color-calendar-fill); border-radius: 10px; padding:1rem 0.5rem;">
<!--      TODO: Get from Google Calendar-->
      <div v-for="(event,index) in events" :key="index">
        <OxEvent :name="event.name" :start="event.start" :end="event.end" :location="event.location" :link="event.link" :sub-events="event.subEvents" :all-day="event.allDay" :date="event.date"/>
      </div>

    </div>
  </div>
</template>

<style scoped>
.cool-calendar {
  font-family: serif;
  border-radius: 5px;
  border-width: 3px;
  border-style: solid;
  border-color: var(--color-calednar-border);
  padding: 1rem;
  width: 95%;
  background-color: var(--color-calednar-border);
}
</style>