import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/main.css'

/* import the fontawesome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faChevronLeft, faChevronRight, faChevronDown, faChevronCircleLeft, faChevronCircleRight, faPlus, faMinus, faTimes, faHeart, faDownload, faSearch, faPrint, faDollarSign} from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(faUserSecret, faChevronLeft, faChevronRight, faChevronCircleLeft, faChevronDown, faChevronCircleRight, faTimes, faHeart, faPlus, faMinus, faDownload, faSearch, faPrint, faDollarSign)

const app = createApp(App)
app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)
app.provide("email", "lehrecke@iastate.edu")
app.mount('#app')

