<script setup>
import OxFooter from "@/components/OxFooter.vue";
import MoneyOverlay from "@/components/MoneyOverlay.vue";

function toggleShow() {
  document.getElementById("nav").classList.toggle("dropHidden");
  document.getElementById("nav").classList.toggle("dropShown");
  document.getElementById("icon").classList.toggle("iconrot");
}
</script>
<template>
  <header class="d-lg-flex flex-lg-row justify-content-lg-start align-items-lg-center">
    <div class="imgdiv d-flex justify-content-evenly justify-content-lg-start flex-row align-items-center justify-content-center m-2">
      <img class="ox-header-img" width="150" height="142.5" src="./assets/ox-cream.png" alt="ox logo">
      <font-awesome-icon id="icon" class="d-lg-none icon" :icon="['fas', 'chevron-down']" @click="toggleShow"/>
    </div>
    <nav class="d-flex flex-column flex-lg-row justify-content-start align-items-start align-items-lg-center gap-1 dropHidden" id="nav">
      <router-link class="ox-nav-item" to="/">Home</router-link>
      <router-link class="ox-nav-item" to="/about">About Us</router-link>
      <router-link class="ox-nav-item" to="/magazine">Magazine</router-link>
      <router-link class="ox-nav-item" to="/submissions">Make a Submission</router-link>
    </nav>
  </header>
  <MoneyOverlay ref="money"/>
  <router-view/>

  <ox-footer/>
</template>

<style>
#app {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
header {
  background-color: var(--maroon);
  box-shadow: 1px 1px 5px 0px var(--maroon-dark);
  top: 0;
  width: 100%;
}
nav {
  width: 100%;
  background-color: var(--color-header-background);
}

@media (max-width: 992px) {
  nav {
    z-index: 99;
    position: fixed;
    overflow: hidden;
    transition: max-height 1s ease;
  }
  .imgdiv {
    width: 100%;
  }
  .dropHidden {
    max-height: 0;
  }
  .dropShown {
    max-height: 500px;
  }
  .iconrot {
    transform: rotate(180deg);
  }
  .icon {
    color: var(--cream);
    font-size: 1.5rem;
    transition: transform .5s ease;
  }
}

.ox-nav-item {
  font-size: 25px;
  font-family: serif;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  color: var(--cream-dark);
  border-radius: 10px;
  transition: all 0.5s ease;
}

.ox-nav-item:hover,.ox-nav-item.router-link-exact-active {
  color: var(--cream-light);
  transition: all 0.5s ease;
}
@media (min-width: 992px) {
  .ox-nav-item:hover,.ox-nav-item.router-link-exact-active {
    font-size: 35px;
    transition: all 0.5s ease;
  }
}

.ox-header-img {
  display: flex;
  justify-content: center;
}
</style>