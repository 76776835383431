<template>
  <div class="ox-cont-header">
    <div class="container">
      <h1>The Ox: Catholic Student Magazine</h1>
      <h5>A Community of Catholic Creatives</h5>
    </div>
  </div>
  <div class="ox-content">
    <div class="container">
      <p>Submissions for <b>Spring 2024</b> will be open until <b>April 5th, 2024</b>!!</p>
      <p>
        We're happy you're here! Go to the submissions page for guidelines on submissions and / or to submit your work: <a href="/submissions" class="ox-link">catholicox.com/submissions</a>
      </p>
      <p>
        Looking for inspiration, or just want to check out the cool work we do? Check out a virtual copy of our first edition from Fall 2023: <a href="/magazine" class="ox-link">catholicox.com/magazine</a>
      </p>
    </div>
  </div>

  <div class="ox-cont-header">
    <div class="container">
      <h1>Upcoming Events</h1>
    </div>
  </div>
  <div class="ox-content">
    <div class="container d-flex flex-column align-items-center">
      <Calendar/>
    </div>
  </div>
</template>

<script>
import Calendar from "@/components/OxCalendar.vue";

export default {
  name: 'HomeView',
  components: {
    Calendar
  }
}
</script>
